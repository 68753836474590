.table-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.aify-table {
    width: 100%;
    border-collapse: collapse;
}

.aify-table thead {
    background-color: #111;
    color: #fff;
}

.aify-table th, .aify-table td {
    padding: 15px;
    text-align: left;
    font-size: 16px;
}

.aify-table th {
    font-weight: 700;
    text-transform: uppercase;
}

.aify-table tbody tr {
    border-bottom: 1px solid #ddd;
}

.aify-table tbody tr:nth-child(even) {
    background-color: #f4f4f4;
}

.aify-table tbody tr:hover {
    background-color: #ddd;
}

.aify-table td {
    font-weight: 400;
}

.aify-table th:first-child, .aify-table td:first-child {
    padding-left: 30px;
}

.aify-table th:last-child, .aify-table td:last-child {
    padding-right: 30px;
}