.service-content {
    background-color: grey;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns, each taking 50% */
    height: 80vh; /* Full viewport height */
}

.service-content img {
    width: 100%;
    height: auto;
    display: block;
}

.left-side, .right-side {
    flex: 1; /* Takes up equal space */
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.left-side {
    background-color: black; /* Light gray background for the left side */
}

.right-side {
    background-color: black; /* Dark background for the right side */
    color: white; /* White text color */
}

/* Headline styling */
.headline {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
    color: #333;
}

/* List styling */
.item-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Individual item styling */
.item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-left: 5px solid darkred;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Item title styling */
.item-title {
    font-size: 1.5em;
    margin: 0 0 10px 0;
    color: black;
}

/* Item description styling */
.item-description {
    font-size: 1em;
    margin: 0;
    color: #666;
}

.image-container {
    width: 100%;
    height: 70vh; /* 60% of the viewport height */
    overflow: hidden; /* Ensures no overflow if the image is larger */
}

/* Image styling */
.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire container */
    display: block; /* Removes any extra space below the image */
}