.contact-wrapper  {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.contact-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.contact-form {
    margin-bottom: 15px;
}

.contact-form label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #555;
}

.contact-form input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f4f4f4;
    transition: border-color 0.3s, background-color 0.3s;
}

.contact-form input:focus {
    border-color: #111;
    background-color: #fff;
    outline: none;
}

button {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #111;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #333;
}

/* Responsive design */
@media (max-width: 480px) {
    .login-container {
        width: 90%;
        padding: 20px;
    }
}