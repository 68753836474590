/* Footer Styles */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: white;
    font-size: 1rem;
    text-align: center;
    flex-shrink: 0; /* Ensures footer stays at the bottom */
}

/* Footer links section */
.footer .footer-links {
    display: flex;
    gap: 15px;
}

.footer .footer-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.footer .footer-links a:hover {
    color: #ddd;
}

/* Copyright Section */
.footer .copyright {
    font-size: 0.9rem;
    color: #aaa;
}

/* Responsive Footer for Mobile */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        gap: 10px;
        padding: 15px;
    }

    .footer .footer-links {
        flex-direction: column;
    }

    .footer .copyright {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 10px;
    }

    .footer .footer-links {
        gap: 10px;
    }

    .footer .copyright {
        font-size: 0.75rem;
    }
}