/* OverlayLogin.css */
div[data-amplify-authenticator] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of everything else */
}

div[data-amplify-container] div[data-amplify-sign-in], .amplify-sign-up, .amplify-forgot-password {
    background-color: #fff; /* White background for the form */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
}

div[data-amplify-container] .amplify-header {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

div[data-amplify-container] .amplify-tabs__item {
    color: black;
    border-color: black;
    background-color: lightgrey;
}

div[data-amplify-container] .amplify-tabs__item--active {
    background-color: white;
}

div[data-amplify-container] .amplify-button {
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #111;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #333;
}