.hiring-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.hiring-page h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.hiring-page p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.hiring-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

p {
    font-size: 1.2em;
}