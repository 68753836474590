/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
    color: white;
}

/* Responsive Header for Mobile */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        text-align: center;
    }

    .header .nav-menu {
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }
}

/* Logo section */
.logo-section {
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
    margin-right: 10px;
}

.site-name {
    font-size: 1.5rem;
    color: white;
    margin: 0;
}

/* Navigation Links */
.nav-links {
    display: flex;
    flex-wrap: wrap; /* Allow the links to wrap to the next line */
    gap: 20px;
}

.nav-links a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
}

.nav-links a:hover {
    color: #ddd;
}

.menu {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.menu-item {
    position: relative;
}

.menu-item a {
    color: white;
    text-decoration: none;
    padding: 10px;
}

.menu-item a:hover {
    background-color: #444;
}

.submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #444;
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

.submenu-item a {
    color: white;
    text-decoration: none;
    padding: 10px;
    display: block;
}

.submenu-item a:hover {
    background-color: #555;
}

/* Login and user info section */
.login-info {
    display: flex;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo-section img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 20px;
}

.login-link, .logout-link {
    color: white;
    text-decoration: none;
}

.login-link:hover, .logout-link:hover {
    text-decoration: underline;
}

/* Hamburger Menu (for mobile) */
.hamburger {
    display: none;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.hamburger-icon {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: transform 0.3s ease-in-out;
}

/* Open hamburger menu styles */
.hamburger-icon.open {
    transform: rotate(45deg);
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    /* Display hamburger on mobile */
    .hamburger {
        display: flex;
    }

    /* Hide nav links by default on mobile */
    .nav-links {
        display: none;
        position: absolute;
        top: 50px;
        right: 0;
        flex-direction: column;
        background-color: #333;
        width: 100%;
        padding: 20px;
        gap: 15px;
    }

    /* Open the menu when hamburger is clicked */
    .nav-links.open {
        display: flex;
    }

    /* Hide login info on mobile */
    .login-info {
        display: none;
    }
}