.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}

.container {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.contentPane {
  flex: 0 0 80%; /* 20% height */
  overflow-y: auto;
}

.App-link {
  color: #61dafb;
}
