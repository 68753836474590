/* Main content styling */
main {
    flex: 1;
    padding: 20px;
    text-align: center;
}

main .content {
    max-width: 800px;
    margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 768px) {
    main {
        padding: 10px;
    }

}